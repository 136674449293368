import { params } from '../_config';

/*
同階層の他のアコーディオン閉じる
*/
const acdReset = (target) => {

  let parent = target.parentNode.parentNode;
  let acdBtns = parent.querySelectorAll('#header .acd');

  //アコーディオン
  for (let j = 0; j < acdBtns.length; j++) {
    if (acdBtns[j].classList.contains('open')) {
      acdBtns[j].classList.remove('open');
    }
  }
}

/* export default */
const HeaderMenuCategory = () => {

  let acdBtns = document.querySelectorAll('#header .mega-menu-category .acd');

  //アコーディオン
  for (let j = 0; j < acdBtns.length; j++) {

    acdBtns[j].addEventListener('click', (e) => {

      e.preventDefault();

      if (acdBtns[j].classList.contains('open')) {
        e.currentTarget.classList.remove('open');
      } else {
        acdReset(e.currentTarget);
        e.currentTarget.classList.add('open');
      }
    });
  }
}

export default HeaderMenuCategory;