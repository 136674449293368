let noticeIsShowing; //緊急のお知らせを表示中かどうか
let noticeAnimLock = false; //アニメーションロック

/*
ヘッダーの高さ分だけmainを下げる
*/
const makeMargin =()=>{
  let headerHeight = document.getElementById('header').offsetHeight;
  let main = document.querySelector('main');
  main.style.marginTop = headerHeight+'px';
}

/*
緊急のお知らせなど
*/

const notice = {
  init:()=>{
    const closeBtns = document.querySelectorAll('#notice .close');
    for(let i=0;i<closeBtns.length;i++){
      closeBtns[i].addEventListener('click',(e)=>{
        let parent = e.currentTarget.parentNode;
        parent.classList.add('hide');
        makeMargin();
      });
    }
  },
  show:()=>{
    noticeIsShowing = true;
    noticeAnimLock = true;
    if (document.getElementById('notice')){
      document.getElementById('notice').classList.remove('hide');
    }
    if (document.getElementById('headerBar01')){
      document.getElementById('headerBar01').classList.remove('hide');
    }
  },
  hide:()=>{
    noticeIsShowing = false;
    noticeAnimLock = true;
    if (document.getElementById('notice')) {
      document.getElementById('notice').classList.add('hide');
    }
    if (document.getElementById('headerBar01')){
      document.getElementById('headerBar01').classList.add('hide');
    }
  }
}

/*
スクロールイベント
*/
const onScroll = () => {
  let scrollY = document.scrollingElement.scrollTop;
  let windowHeight = window.innerHeight;

  //緊急のお知らせ　出し入れ
  if(scrollY > windowHeight * 0.2){
    if(noticeIsShowing){
      notice.hide();
    }
  } else {
    if (noticeIsShowing == false){
      notice.show();
    }
  }
};

const startScrollEvent = () => {
  //一番最初のスクロールイベントを無視する
  window.addEventListener(
    "scroll",
    () => {
      window.addEventListener("scroll", () => {
        let scrollY = document.scrollingElement.scrollTop;
        let windowHeight = window.innerHeight;

        //緊急のお知らせ 出し入れ
        if (scrollY > windowHeight * 0.2) {
          if (noticeIsShowing) {
            notice.hide();
          }
        } else {
          if (noticeIsShowing == false) {
            notice.show();
          }
        }
      });
    },
    { once: true }
  );
};


/*
メガメニュー開閉（SP）　（PCはcssで開閉)
*/

//全てのメガメニュー閉じる
const megaMenuReset = ()=>{
  const megaMenus = document.querySelectorAll('#header .mega-menu');
  const megaBtns = document.querySelectorAll('#header .mega-btn');

  for (let i = 0; i < megaMenus.length; i++) {
    if(megaMenus[i].classList.contains('open')){
      megaMenus[i].classList.remove('open');
    }
  }
  for (let i = 0; i < megaBtns.length; i++) {
    if (megaBtns[i].classList.contains('open')) {
      megaBtns[i].classList.remove('open');
    }
  }
}

const addMegaBtnsEvent = ()=>{

  const megaBtns = document.querySelectorAll('#header .mega-btn');

  for(let i=0;i<megaBtns.length;i++){
    megaBtns[i].addEventListener('click',(e)=>{
      const parent = e.currentTarget.parentNode;
      const megaMenu = parent.querySelector('.mega-menu');
      if (megaMenu.classList.contains('open')) {
        megaMenu.classList.remove('open');
      } else {
        megaMenuReset();
        megaMenu.classList.add('open');
        e.currentTarget.classList.add('open');
        let megaClick;

        document.addEventListener('click',function docClick(e){
          megaMenuReset();
          e.currentTarget.removeEventListener('click',docClick);
          megaMenu.removeEventListener('click',megaClick);
        });
        megaMenu.addEventListener('click',megaClick=(e)=>{
          e.stopPropagation();
        });
        e.stopPropagation();
      } 
    });
  }
}


/* export default */
const Header = {
  init:()=>{
    
    const headerElem = document.getElementById('header');

    if(headerElem!=null){
      
      makeMargin();
      notice.init();
      addMegaBtnsEvent();

      setTimeout(()=>{
        //念の為もう一度処理
        makeMargin();
        startScrollEvent();
      },500)
      
      notice.show();
    }
  },
  showNotice:()=>{
    notice.show();
  }
}

export default Header;