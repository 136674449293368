// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;
require('@rails/ujs').start()
require('@rails/activestorage').start()
import Header from "stylesheets/js/common/_header.js";
import HeaderMenuCategory from "stylesheets/js/common/_header_menu_category.js";
import eventBus from "./eventbus.js";

// Import Swiper JS and CSS
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
window.Swiper = Swiper;

window.eventBus = eventBus;

document.addEventListener("DOMContentLoaded", () => {
  Header.init();
  HeaderMenuCategory();

  // partial copy from stylesheets/js/common/_include.js
  const backToTop = document.getElementById('backToTop');
  backToTop.addEventListener('click', (e) => {
    const target = document.getElementsByTagName('body');

    let top = 0;
    window.scrollTo({
      top,
      behavior: "smooth"
    });
  })
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
